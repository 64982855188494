import React from 'react'
import Head from './Head'
import heroImage from '../images/hero-image.avif'

const keywords = [
  'hairdresser labrador',
  'best hairdresser labrador',
  'best salon labrador',
  'best womens hairdresser',
  'boutique salon labrador',
  'hair salon labrador',
  'hair salon southport',
  'cpr hair salon',
  'blonde hairdresser',
  'hair salon near me',
  'hairdresser near me',
  'kids haircuts',
  'natural hair salon',
  'hair colour',
  'in labrador gold coast',
  'little hair lounge',
  'little hair lounge labrador',
  'little hair lounge southport',
]

export default function Layout({ children }) {
  return (
    <div>
      <Head
        title="Hair Salon Labrador | Little Hair Lounge"
        keywords={keywords}
        image={{ src: heroImage, height: 630, width: 1200 }}
      />
      {children}
    </div>
  )
}
